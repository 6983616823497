import React from "react";
import * as qf from "../../misc/qf";
import "./TeamNavigationSelectMenu.css";
import compareLevels from "./customSorterHelper.js";

class TeamNavigationSelectMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      initialFilteredTeamsById: [],
      initialFilteredTeamsByYear: [],
      initialFilteredTeamsByGender: [],
      filteredTeamsById: [],
      filteredTeamsByYear: [],
      filteredTeamsByGender: [],
      initial_team_years: [],
      initial_team_genders: [],
      initial_team_levels: [],
      initial_team_pages: [],
      team_years: [],
      team_genders: [],
      team_levels: [],
      team_pages: [],
      sport_id: "",
      year: "",
      team_gender: "",
      level: "",
      page: "Team",
      initial: true,
      useTeamId: false,
      teamType: "",
      showModalBtn: true,
    };
    this._allSchoolSports = {};
    this.typeTeam = "";
  }

  _filterSportsById = (id = this.state.sport_id, reset = false) => {
    const sportFilterList = this._allSchoolSportsTeams.filter((team) =>
      team.sport_id !== 1 ? team.sport_id === parseInt(id) : team.sport_id === parseInt(id) && team.active_end !== undefined
    );
    1 === parseInt(id) ? this.setState({ useTeamId: true }) : this.setState({ useTeamId: false });
    if (this.state.initial) {
      this.setState(
        {
          initialFilteredTeamsById: sportFilterList,
          filteredTeamsById: sportFilterList,
          sport_id: id,
        },
        () => this._setSportYears(reset)
      );
      return;
    }
    this.setState(
      {
        filteredTeamsById: sportFilterList,
        sport_id: id,
      },
      () => this._setSportYears(reset)
    );
  };

  _filterSportByYears = (year = this.state.year, reset = false) => {
    let sportYearList = this.state.filteredTeamsById.filter(
      (team) => team.sport_id === 1 ? parseInt(team.active_begin.split("-")[0]) + 1 === parseInt(year) : effectiveSchoolYear(team) === parseInt(year)
    );

    if (sportYearList.length === 0) {
      year = this.state.filteredTeamsById[0].school_year;
      sportYearList = this.state.filteredTeamsById.filter(
        (team) => team.sport_id === 1 ? parseInt(team.active_begin.split("-")[0]) + 1 === parseInt(year) : effectiveSchoolYear(team) === parseInt(year)
      );
    }
    if (this.state.initial) {
      this.setState(
        {
          initialFilteredTeamsByYear: sportYearList,
          filteredTeamsByYear: sportYearList,
          year: year,
        },
        () => this._setSportGenders(reset)
      );
      return;
    }
    this.setState(
      {
        filteredTeamsByYear: sportYearList,
        year: year,
      },
      () => this._setSportGenders(reset)
    );
  };

  _filterSportByGender = (gender = this.state.team_gender, reset = false) => {
    const sportGenderList = this.state.filteredTeamsByYear.filter(
      (team) => team.gender === gender
    );
    if (this.state.initial) {
      this.setState(
        {
          initialFilteredTeamsByGender: sportGenderList,
          filteredTeamsByGender: sportGenderList,
          team_gender: gender,
        },
        () => this._setSportLevels(reset)
      );
      return;
    }
    this.setState(
      {
        filteredTeamsByGender: sportGenderList,
        team_gender: gender,
      },
      () => this._setSportLevels(reset)
    );
  };

  _filterSportByLevel = (level = this.state.level, reset = false) => {
    let sportLevelList = [];
    sportLevelList = this.state.filteredTeamsByGender.filter((team) => {
      return team.sport_name === "Band" || team.current_club
        ? team.id == level
        : team.level === level;
    });
    let keys = Object.keys(sportLevelList[0]._links);
    const selfIdx = keys.indexOf("self");
    keys[selfIdx] = "Team";
    keys = keys.map((str) => {
      let strArr = str.split("");
      strArr[0] = strArr[0].toUpperCase();
      str = strArr.join("");
      return str;
    });
    if (this.state.initial) {
      this.setState({
        initial_team_pages: keys,
        team_pages: keys,
      });
      return;
    }
    this.setState({
      team_pages: keys,
    });
  };

  _setSportYears = (reset = false) => {
    let years = new Set();
    for (let team of this.state.filteredTeamsById) {
      if(team.sport_id === 1) {
        const yearFromActiveBegin = parseInt(team.active_begin.split("-")[0]) + 1;
        years.add(yearFromActiveBegin)
      } else {
        years.add(effectiveSchoolYear(team));
      }
    }
    if (this.state.initial) {
      this.setState({
        team_years: Array.from(years),
        initial_team_years: Array.from(years),
      });
    }
    if (reset) {
      this.setState({ team_years: Array.from(years) });
    } else {
      this.setState({
        team_years: Array.from(years),
        year: "",
        team_genders: [],
        team_gender: "",
        team_levels: [],
        level: "",
        team_pages: [],
        page: "Team",
      });
    }
  };

  _setSportGenders = (reset = false) => {
    let genders = new Set();
    this.state.filteredTeamsByYear.forEach((team) => genders.add(team.gender));
    genders = Array.from(genders);
    if (this.state.initial) {
      if (genders.length > 1) {
        this.setState({
          team_genders: genders,
          initial_team_genders: genders,
        });
      } else {
        this.setState({
          team_genders: genders,
          team_gender: genders[0],
          initial_team_genders: genders,
        });
      }
    }
    if (reset) {
      this.setState({ team_genders: genders });
    } else if (genders.length > 1) {
      this.setState({
        team_genders: genders,
        team_gender: "",
        team_levels: [],
        level: "",
      });
    } else {
      this.setState(
        {
          team_genders: genders,
          team_gender: genders[0],
          team_levels: [],
          level: "",
        },
        () => {
          this._filterSportByGender();
        }
      );
    }
  };

  _setSportLevels = (reset = false) => {
    let levels = new Set();
    if (this.state.useTeamId) {
      this.state.filteredTeamsByGender.forEach((team) =>
        levels.add([team.id, team.short_name || team.team_title])
      );
    } else {
      if (this.typeTeam === "clubs") {
        this.state.filteredTeamsByGender.forEach((team) =>
          levels.add([team.level, team.team_name || team.team_title, team.id])
        );
      } else {
        this.state.filteredTeamsByGender.forEach((team) =>
          levels.add([team.level, team.level_name])
        );
      }
    }
    if (this.state.initial) {
      this.setState({
        team_levels: Array.from(levels),
        initial_team_levels: Array.from(levels),
      });
    }
    if (reset) {
      this.setState({ team_levels: Array.from(levels) });
    } else {
      this.setState({
        team_levels: Array.from(levels),
        level: "",
      });
    }
  };

  _initialSetup = () => {
    this._filterSportsById(this.props.sport_id, true);
    let schoolYear;
    this.props.team.sport_id === 1 ? schoolYear = parseInt(this.props.team.active_begin.split("-")[0]) + 1 : schoolYear = effectiveSchoolYear(this.props.team);
    this._filterSportByYears(schoolYear, true);
    this._filterSportByGender(this.props.team_gender, true);
    this._filterSportByLevel(this.props.team_level, true);
    this.setState({ initial: false });
  };

  _handleOnChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (this.state.useTeamId && name === "level")
      value = parseInt(e.target.value);
    this.setState({ [name]: value });
    switch (name) {
      case "sport_id":
        this._filterSportsById(value);
        break;
      case "year":
        this._filterSportByYears(value);
        break;
      case "team_gender":
        this._filterSportByGender(value);
        break;
      case "level":
        this._filterSportByLevel(value);
        break;
      case "page":
        this.setState({ page: value });
      default:
        break;
    }
  };

  _getModalInfo = () => {
    let { modalInfo } = this.props;
    if (!modalInfo.school_sports) modalInfo = modalInfo[0];
    return modalInfo;
  };

  _resetValues = () => {
    const modalInfo = this._getModalInfo();
    if (!modalInfo.school_sports) modalInfo = modalInfo[0];
    let level = "";
    this.typeTeam === "bands" || this.typeTeam === "clubs"
      ? (level = modalInfo.id)
      : (level = modalInfo.team_level);
    this.setState({
      filteredTeamsById: this.state.initialFilteredTeamsById,
      filteredTeamsByYear: this.state.initialFilteredTeamsByYear,
      filteredTeamsByGender: this.state.initialFilteredTeamsByGender,
      team_years: this.state.initial_team_years,
      team_genders: this.state.initial_team_genders,
      team_levels: this.state.initial_team_levels,
      team_pages: this.state.initial_team_pages,
      sport_id: modalInfo.sport_id,
      year: modalInfo.school_year,
      team_gender: modalInfo.team_gender,
      level: level,
      page: "Team",
    });
  };

  _submit = (e) => {
    e.preventDefault();
    if (e.target.type === "cancel") return;
    const filteredTeam = this.state.filteredTeamsByGender.filter((team) => {
      return this.state.useTeamId || team.current_club
        ? team.id === parseInt(this.state.level)
        : team.level === this.state.level;
    });
    switch (this.state.page) {
      case "Schedule":
        window.location = filteredTeam[0]._links.schedule.href;
        break;
      case "Roster":
        window.location = filteredTeam[0]._links.roster.href;
        break;
      case "Fundraiser":
        window.location = filteredTeam[0]._links.fundraiser.href;
        break;
      case "Team":
      default:
        window.location = filteredTeam[0]._links.self.href;
    }
  };

  componentDidMount() {
    const modalInfo = this._getModalInfo();
    this._allSchoolSports = modalInfo.school_sports.sort();
    const arrCurrUrlPath = window.location.pathname.split("/");
    let currPage = "team";
    switch (arrCurrUrlPath[arrCurrUrlPath.length - 1]) {
      case "schedule":
        currPage = "Schedule";
        break;
      case "roster":
        currPage = "Roster";
        break;
      case "fundraiser":
        currPage = "Fundraiser";
        break;
      default:
        currPage = "Team";
    }
    this.setState({
      sport_id: modalInfo.sport_id,
      year: modalInfo.school_year,
      team_gender: modalInfo.team_gender,
      level: modalInfo.team_level,
      page: currPage,
    });

    let url = "";

    if (this.props.team.school_id) {
      url = `/schools/${this.props.team.school_id}/teams.json`;
      this.typeTeam = "schools";
    } else if (this.props.team.current_club) {
      url = `/clubs/${this.props.team.current_club.id}/teams.json`;
      this.typeTeam = "clubs";
    } else if (this.props.team.band_id) {
      url = `/bands/${this.props.team.band_id}/groups.json`;
      this.typeTeam = "bands";
    }

    if(url !== '') {
      qf.getJSON(url)
        .then(async (res) => {
          let hasBand = false;
          let addBand = true;
          let bandId = null;
          let i = 0;
          for (i; i < res.teams.length; i++) {
            if (res.teams[i].sport_id === 1) {
              hasBand = true;
              bandId = res.teams[i].band_id;
              break;
            }
          }
  
          if (res.band === "Band" && res.school_id) {
            await qf
              .getJSON(`/schools/${res.school_id}/teams.json`)
              .then((res2) => {
                let sportsTeams = res.teams.concat(res2.teams);
                res.teams = sportsTeams;
              });
          } else if (hasBand) {
            for (let i = 0; i < this._allSchoolSports.length; i++) {
              if (this._allSchoolSports[i][0] === "Band") {
                addBand = false;
              }
            }
            if (addBand) {
              this._allSchoolSports.push(["Band", 1]);
              await qf.getJSON(`/bands/${bandId}/groups.json`).then((res2) => {
                let withBandTeams = res.teams.concat(res2.teams);
                res.teams = withBandTeams;
              });
            }
            this._allSchoolSports.sort();
          }
          if (res.club_type === "league" || res.band === "Band") {
            this.setState({ useTeamId: true, level: modalInfo.id });
          }
          if (this.typeTeam === "clubs") this.setState({ level: modalInfo.id });
          this._allSchoolSportsTeams = res.teams;
          if (this.typeTeam === "clubs") {
            this._allSchoolSportsTeams.sort(compareLevels);
          }
          this._initialSetup();
        })
        .catch((err) => console.error("Error", err));
    } else {
      this.setState({ showModalBtn: false });
    };
  };

  render() {
    let teamName = "";
    const { team } = this.props;
    if (team.sport_id === 29) {
      // boys and girl's club
      teamName = team.name;
    } else if (team.level === "alumni") {
      teamName = team.team_title;
    } else if (team.sport_id === 25 && team.custom_sport == "") {
      team.record;
    } else {
      teamName = team.title;
    }
    let year2 = this.props.team.sport_id === 1 ? parseInt(this.props.team.active_begin.split("-")[0]) + 1 : effectiveSchoolYear(this.props.team);
    let year1 = year2 - 1;
    let modal = (
      <div
        className="modal fade"
        id="staticOptionsSelector"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="cancel"
                onClick={this._resetValues}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <img
                src={this.props.team._links.profile_picture.href}
                alt={this.props.team.short_name}
              />
              <h5 className="modal-title" id="staticOptionsSelectorLabel">
                {this.props.team.short_name}
              </h5>
              <p className="CurrentInfo">
                ({this.props.team.team_title} - {year1}&#8201;&#8211;&#8201;
                {year2})
              </p>
            </div>
            <form onSubmit={this._submit}>
              <div className="modal-body">
                <select
                  className="form-select mb-3"
                  aria-label="Select sport"
                  onChange={this._handleOnChange}
                  name="sport_id"
                  value={this.state.sport_id ? this.state.sport_id : ""}
                >
                  <option>Select Sport</option>
                  {this._allSchoolSports.length > 0 &&
                    this._allSchoolSports.map((sport, idx) => {
                      return (
                        <option value={sport[1]} key={`sport-${idx}`}>
                          {sport[0]}
                        </option>
                      );
                    })}
                </select>
                <select
                  className="form-select mb-3"
                  aria-label="Select team year"
                  onChange={this._handleOnChange}
                  name="year"
                  value={this.state.year ? this.state.year : ""}
                >
                  <option>Select Team Year</option>
                  {this.state.team_years &&
                    this.state.team_years.length > 0 &&
                    this.state.team_years
                      .sort((a, b) => b - a)
                      .map((year, idx) => {
                        return (
                          <option value={year} key={`teamYears-${idx}`}>
                            {year - 1}&#8201;&#8211;&#8201;{year}
                          </option>
                        );
                      })}
                </select>
                <select
                  className="form-select mb-3"
                  aria-label="Select team title"
                  onChange={this._handleOnChange}
                  name="team_gender"
                  value={
                    this.state.team_genders.length === 1
                      ? this.state.team_genders[0]
                      : this.state.team_gender
                  }
                  disabled={
                    this.state.team_genders.length === 1 ? true : false
                  }
                >
                  <option>Select Gender</option>
                  {this.state.team_genders &&
                    this.state.team_genders.length > 0 &&
                    this.state.team_genders.map((gender, idx) => {
                      const genderNames = {
                        m: "Boys",
                        w: "Girls",
                        mw: "Coed",
                      };
                      return (
                        <option value={gender} key={`gender-${idx}`}>
                          {genderNames[gender]}
                        </option>
                      );
                    })}
                </select>
                <select
                  className="form-select mb-3"
                  aria-label="Select sport level"
                  onChange={this._handleOnChange}
                  name="level"
                  value={this.state.level ? this.state.level : ""}
                >
                  <option>
                    {this.state.useTeamId
                      ? "Select Team"
                      : "Select Sport Level"}
                  </option>
                  {this.state.useTeamId
                    ? this.state.team_levels &&
                      this.state.team_levels.length > 0 &&
                      this.state.team_levels.map((level, idx) => {
                        return (
                          <option value={level[0]} key={`level-${idx}`}>
                            {level[1]}
                          </option>
                        );
                      })
                    : this.state.team_levels &&
                      this.state.team_levels.length > 0 &&
                      this.state.team_levels.map((level, idx) => {
                        if (this.typeTeam === "clubs") {
                          return (
                            <option value={level[2]} key={`level-${idx}`}>
                              {level[0].trim() !== "" && `${level[0]} - `}
                              {level[1]}
                            </option>
                          );
                        } else {
                          return (
                            <option value={level[0]} key={`level-${idx}`}>
                              {level[1]}
                            </option>
                          );
                        }
                      })}
                </select>
                <select
                  className="form-select mb-3 text-capitalize"
                  aria-label="Select team page"
                  onChange={this._handleOnChange}
                  name="page"
                  value={this.state.page ? this.state.page : ""}
                >
                  <option>Select Page</option>
                  {this.state.team_pages &&
                    this.state.team_pages.length > 0 &&
                    this.state.team_pages.map((page, idx) => {
                      return (
                        <option value={page} key={`page-${idx}`}>
                          {page}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this._resetValues}
                  className="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={this._gotoTeam}
                  className="btn u-layoutBtnColor"
                >
                  Go
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
    if(!this.state.showModalBtn) {
      return (
        <div className="m-0 h-100 d-flex justify-content-end align-items-center text-capitalize">
          {/* this.props.team.team_title} -{" " */}
          { this.props.team.sport_name}
          <span className="text-nowrap ms-2">
            {String(year1)}&#8201;&#8211;&#8201;{String(year2)}
          </span>
        </div>
      )
    }
    return (
      <>
        <button
          className="btn selector"
          data-bs-toggle="modal"
          data-bs-target="#staticOptionsSelector"
        >
          {this.props.team.team_title} -{" "}
          <span className="text-nowrap">
            &lsquo;{String(year1).slice(-2)}&#8201;&#8211;&#8201;&lsquo;
            {String(year2).slice(-2)}
            <i className="fa-solid fa-gear"></i>
          </span>
        </button>
        { modal }
      </>
    );
  }
}

function effectiveSchoolYear(team) {
  if (team.school_year) {
    return team.school_year;
  }
  return parseInt(team.active_end.slice(0, 4), 10);
}

export default TeamNavigationSelectMenu;