const compareLevels = (a, b) => {
  const levelA = a.level;
  const levelB = b.level;

  // Extract the numeric part from the level string
  const numA = parseInt(levelA.match(/\d+/));
  const numB = parseInt(levelB.match(/\d+/));

  // Check if levelA and levelB starts with a letter
  const isLetterA = isNaN(levelA[0]);
  const isLetterB = isNaN(levelB[0]);

  // Compare the numeric values if both levels start with numbers
  if (!isLetterA && !isLetterB) {
    if (numA < numB) {
      return -1;
    } else if (numA > numB) {
      return 1;
    } else {
      // If the numeric values are the same, compare the strings with case sensitivity
      // but with lowercase letters coming before uppercase letters
      if (levelA.toLowerCase() < levelB.toLowerCase()) {
        return -1;
      } else if (levelA.toLowerCase() > levelB.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    }
  } else if (isLetterA && isLetterB) {
    // If both levels start with letters, compare the strings with case sensitivity
    // but with lowercase letters coming before uppercase letters
    if (levelA.toLowerCase() < levelB.toLowerCase()) {
      return -1;
    } else if (levelA.toLowerCase() > levelB.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  } else if (isLetterA) {
    // If only levelA starts with a letter, levelA should come after levelB
    return 1;
  } else {
    // If only levelB starts with a letter, levelA should come before levelB
    return -1;
  }
};

export default compareLevels;
