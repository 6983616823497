import ReactDOM from 'react-dom';
import preloaded from '../../src/preloaded.js';
import TeamNavigationSelectMenu from '../../src/teams/2022/TeamNavigationSelectMenu.jsx';

const navEl = document.getElementById('TeamPageSportSelect');

if (navEl && preloaded.modal_info) {
  ReactDOM.render(
    <TeamNavigationSelectMenu
      team={preloaded.team}
      modalInfo={preloaded.modal_info}
    />
  , navEl);
} else if (navEl !== null) {
  navEl.remove();
}
